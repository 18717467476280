import React from "react"
import styled from "styled-components"

import HelmetVideo from "../../../content/assets/film1.mp4"

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 101;
  display: ${props => (props.showAnimation ? "flex" : "none")};
  background-color: #d5beb4;
  & > video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 45vh;
    width: 45vw;
  }
`

const Animation = ({ showAnimation }) => {
  return (
    <Wrapper showAnimation={showAnimation}>
      <video autoPlay muted loop>
        <source src={HelmetVideo} type="video/mp4" />
      </video>
    </Wrapper>
  )
}

export default Animation
