import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Container from "../../Container/index"
import MotoIcon from "../../../content/assets/motorcycleIcon.png"
import NavigationRoutesList from "../NavigationRoutesList"

const Wrapper = styled.nav`
  display: flex;
  z-index: 2;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 8px 0px;
  box-shadow: -3px -12px 20px 0px #000000;
  background-color: ${({ theme }) => theme.colors.white};
`

const NavigationItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NavigationLogoContainer = styled.div`
  display: flex;

  & > img {
    width: 80px;
  }
`

const LogoDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.size.xxs};
  line-height: 1.2;

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-left: 12px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-left: 24px;
  }
`

const Navigation = () => {
  return (
    <Wrapper>
      <Container>
        <NavigationItemsWrapper>
          <Link to="/">
            <NavigationLogoContainer>
              <img src={MotoIcon} alt="Motorcycle Logo" />
              <LogoDescription>
                <p>Motorcycle</p>
                <p>Adventure</p>
              </LogoDescription>
            </NavigationLogoContainer>
          </Link>

          <NavigationRoutesList />
        </NavigationItemsWrapper>
      </Container>
    </Wrapper>
  )
}

export default Navigation
