const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1500,
  xxlg: 1920,
}

export const theme = {
  colors: {
    white: `#FFF`,
    black: `#111`,
    grayishCyan: "#818383",
    grayishBlue: "#F1F2F5",
    vividBlue: "#2178FF",
    pureYellow: "#fff200",
  },
  font: {
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      xbold: 800,
    },
    size: {
      xxs: `14px`,
      xs: `18px`,
      sm: `20px`,
      md: `24px`,
      lg: `28px`,
      xlg: `32px`,
    },
  },
  gradient: {
    defaultBlueGradient: `transparent linear-gradient(134deg, #2178ff 0%, #6aa4ff 100%) 0%
    0% no-repeat padding-box`,
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`

    return acc
  }, {}),
}
