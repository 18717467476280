import React from "react"
import styled, { ThemeProvider } from "styled-components"

import Animation from "../components/Animation"
import { theme } from "../theme/theme"
import GlobalStyle from "../theme/globalStyles"

const Wrapper = styled.div`
  max-height: ${props => props.showAnimation && "100vh"};
  max-width: ${props => props.showAnimation && "100vw"};
  overflow: ${props => props.showAnimation && "hidden"};
`

const Layout = ({ children, showAnimation }) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper showAnimation={showAnimation}>
        <Animation showAnimation={showAnimation} />
        {children}
      </Wrapper>
    </ThemeProvider>
  </>
)

export default Layout
