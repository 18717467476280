import React from "react"
import styled from "styled-components"

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayishBlue};
  color: ${({ theme }) => theme.colors.grayishCyan};
  padding: 8px 0px;
  text-align: center;
  width: 100%;

  ${({ theme }) => theme.mq.sm} {
    padding: 16px 0px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 24px 0px;
  }
`

const CopyrightParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};

  ${({ theme }) => theme.mq.md} {
    font-size: 1.6rem;
  }
`

const AuthorParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};
`

const Footer = () => {
  return (
    <Wrapper>
      <CopyrightParagraph>
        Copyright www.motorcycle-adventure.eu © 2020. All Rights Reserved.
      </CopyrightParagraph>
      <AuthorParagraph>Wykonanie SW Development</AuthorParagraph>
    </Wrapper>
  )
}

export default Footer
