import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 0 10px;
  width: 100%;

  ${({ theme }) => theme.mq.sm} {
    width: 750px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.md} {
    width: 970px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 1190px;
  }
  ${({ theme }) => theme.mq.xlg} {
    width: 1480px;
  }
  ${({ theme }) => theme.mq.xxlg} {
    width: 1726px;
  }
`

const Layout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Layout
