import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const RoutesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100vh;
  z-index: 99;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.white};
  transition: transform 0.3s ease-in-out;

  &.activeMenu {
    transform: translateX(0);
  }

  ${({ theme }) => theme.mq.md} {
    position: static;
    width: 760px;
    height: 100%;
    transform: translateX(0);
    flex-wrap: nowrap;
  }

  ${({ theme }) => theme.mq.lg} {
    width: 800px;
  }
`

const RoutesListItem = styled.li`
  display: flex;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.xxs};
  justify-content: center;
  white-space: nowrap;

  ${({ theme }) => theme.mq.md} {
    width: fit-content;
  }

  ${({ theme }) => theme.mq.lg} {
    font-size: 16px;
  }

  & > a {
    position: relative;
  }
  & > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.vividBlue};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  & > a.active {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:before {
      visibility: visible;
      transform: scaleX(1);
      transition: all 0.3s ease-in-out 0s;
    }
  }
`

export const Hamburger = styled.button`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  z-index: 100;
  border: 0;
  margin: 0;
  transition: transform 0.3s 0.1s ease-in-out;
  &:hover,
  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.grayishCyan};
  }

  ${({ theme }) => theme.mq.md} {
    display: none;
  }
`

export const HamburgerBox = styled.span`
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
`

export const HamburgerInner = styled.span`
  width: 100%;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;
  &:before {
    content: "";
    left: 0;
    transition: transform 0.2s 0.2s ease-in-out;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.black};
    position: absolute;
  }
  &:after {
    content: "";
    left: 0;
    transition: transform 0.2s 0.2s ease-in-out;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.black};
    position: absolute;
  }
  &:before {
    top: -10px;
  }
  &:after {
    top: 10px;
  }
  &.hamburger--active {
    background-color: transparent;
    &:after {
      transform: translateY(-10px) rotate(-45deg);
    }
    &:before {
      transform: translateY(10px) rotate(45deg);
    }
  }
`

const NavigationRoutesList = () => {
  const handleMobileMenu = () => {
    const hamburger = document.querySelector(".hamburger__inner")
    const nav = document.querySelector(".routesList")
    hamburger.classList.toggle("hamburger--active")
    nav.classList.toggle("activeMenu")
  }
  return (
    <>
      <Hamburger className="hamburger" onClick={() => handleMobileMenu()}>
        <HamburgerBox className="hamburger__box">
          <HamburgerInner className="hamburger__inner"></HamburgerInner>
        </HamburgerBox>
      </Hamburger>
      <RoutesList className="routesList">
        <RoutesListItem>
          <Link to="/" activeClassName="active">
            Strona główna
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/miasta" activeClassName="active">
            Miasta
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/fotografia" activeClassName="active">
            Fotografia
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/filmy" activeClassName="active">
            Filmy
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/wyprawy" activeClassName="active">
            Wyprawy
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/zabytkowe" activeClassName="active">
            Motocykle zabytkowe
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/aktualnosci" activeClassName="active">
            Aktualności
          </Link>
        </RoutesListItem>
        <RoutesListItem>
          <Link to="/kontakt" activeClassName="active">
            Kontakt
          </Link>
        </RoutesListItem>
      </RoutesList>
    </>
  )
}

export default NavigationRoutesList
